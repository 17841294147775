
    <template>

    <div>

            <div style="margin-left:5px;max-height:230px; position: relative;">
                <!-- the brief version -->
                <div style=" width:100%;">
                    <div class="qmediumcircularimage qhighlight" style="position:absolute;left:0px;top:0px"><img style="height:45px;width:45px" src="/assets/icons/horses-mouth.png"/></div>
                        <div class="grid-x vertical-align text-center" style="padding-top:5px; padding-left:15px;padding-right: 5px"> 
                            <div class="cell small-2 qmajorheader"></div>
                            <div class="cell auto qmajorheader qsmallfontplus1" v-html="langConv('Get it straight from the horses mouth')"></div>
                            <div class="cell shrink qmajorheader" style="padding-top:8px;padding-right:5px"><actionbutton routerlinkpath="/Horses-Mouth" buttontext="more" ></actionbutton></div>
                        </div> 
                    </div>
                </div>
                <div v-if="param1 == 'brief'" style="overflow:auto; max-height:198px">
                    <blogview view="brief"/>
                </div>
         
            </div>
           
    </template>



<script>

    import blogview from '../components/blogview.vue';
    import tab from '../modified3rdpartycomponents/tab.vue'
    import tabs from '../modified3rdpartycomponents/tabs.vue'
    import actionbutton from '../components/actionbutton.vue'

    export default {
        name: "horsesmouth",
        data: function data() {
            return {


            }
        },
        components: {

            blogview,
            actionbutton


        },
        created() {

        },



        props: {
            param1: {type: String, default: "0"}
        },
         methods: {
           changeBlogView: function changeBlogView(newVal) {

                if (this.blogviewval == newVal.tab.id) {
                    return;
                } else {
                    this.blogviewval = newVal.tab.id;
                }
            }


        }
    }

</script>